<template>
  <v-btn
    icon
    x-small
    @click="$emit('reload')"
  >
    <v-icon
      small
      :class="{'mdi-spin': loading}"
    >
      mdi-reload
    </v-icon>
  </v-btn>
</template>
<script>
export default {
  props: {
    loading: Boolean,
  },
}
</script>
